<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <b-row
          v-if="canModify"
          class="mx-2 my-1"
        >
          <b-col cols="4">
            <b-form-input
              v-model="folderNameFilter"
              type="search"
              :placeholder="$t('document.searchNameFolder')"
              :value="folderNameFilter"
              :reduce="val => val.value"
              maxlength="255"
              debounce="500"
              trim
            />
          </b-col>
          <b-col cols="8">
            <div class="d-flex align-items-center justify-content-end">
              <!-- ANCHOR: Button create -->
              <b-button
                variant="info"
                class="mx-50"
                @click="openModalEdit({})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer mr-25"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('document.createFolder') }}
                </span>
              </b-button>

              <!-- FIXME: update sau -->
              <!-- <b-button
                v-if="false"
                class="ml-1"
                variant="primary"
                @click="() => $router.push({name: 'apps-documents-folder-tree'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="ListIcon"
                    size="16"
                  />
                  View Directory Tree
                </span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <IAmOverlay :loading="!!loading">
        <b-table
          ref="refFolderListTable"
          style="max-height: 65vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchAllFolders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ data.label ? $t(`document.columns.folders.${data.label}`) : '' }}
            </span>
          </template>

          <template #cell(action)="data">
            <div v-if="canModify">
              <b-button
                v-b-tooltip.hover.v-info
                variant="flat-info"
                class="px-1 mr-50"
                :title="isMobileView ? '' : 'Đổi tên thư mục'"
                @click="openModalEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-danger
                variant="flat-danger"
                class="px-1"
                :title="isMobileView ? '' : 'Xoá thư mục'"
                @click="confirmDelete(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>
            </div>
          </template>

          <template #cell(name)="data">
            <div
              class="d-flex"
              style="max-width: 500px;"
            >
              <div class="w-100 flex-grow-1">
                <b-link
                  :to="{
                    name: 'apps-documents-list',
                    params: { id: data.item.id },
                  }"
                  class="font-weight-bold d-block text-info"
                >
                  {{ data.item.name }}
                </b-link>
              </div>
            </div>
          </template>

          <template #cell(createdAt)="data">
            <div>
              {{ dateTime(data.item.createdAt) }}
            </div>
          </template>

          <template #cell(updatedAt)="data">
            <div>
              {{ dateTime(data.item.updatedAt) }}
            </div>
          </template>
        </b-table>
      </IAmOverlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-1 d-flex-center justify-content-sm-start">
        <div class="text-muted text-nowrap">
          {{ $t('paginationText.total') }} {{ totalFolders }} {{ $t('document.folder') }}
        </div>
      </div>
    </b-card>

    <!-- Modal Create Document Folder -->
    <ModalFolder
      :folder-data="folderData"
      @refetch-data="refetchFolderData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink, VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import {
  onUnmounted,
  ref,
  computed,
  watch,
} from '@vue/composition-api'

import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'
import { dateTime } from '@/@core/utils/filter'

import useDocumentHandle from '@document/useDocumentHandle'
import documentStoreModule from '@document/documentStoreModule'

import ModalFolder from './ModalFolder.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    IAmOverlay,
    ModalFolder,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const DOCUMENT_APP_STORE_MODULE_NAME = 'app-documents'

    // Register module
    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
      }
    })

    const folderData = ref({})
    function openModalEdit(item) {
      folderData.value = item
      this.$root.$emit('bv::show::modal', 'modal-create-folder')
    }

    const {
      refFolderListTable,
      fetchAllFolders,
      folderNameFilter,
      refetchFolderData,
      folderTableColumns,
      totalFolders,
      deleteDocument,
      loading,
      canModify,
    } = useDocumentHandle()

    const tableColumns = computed(() => !canModify.value ? folderTableColumns.value.filter(item => item.key !== 'action') : folderTableColumns.value)

    watch(
      [folderNameFilter],
      () => {
        refetchFolderData()
      },
    )

    function confirmDelete(item) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `<span class='text-break'>Bạn có muốn xoá thư mục <em class='font-weight-bolder'>${item?.name}</em> ?</span>` } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'XOÁ THƯ MỤC',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteDocument(item)
          }
        })
    }

    return {
      refFolderListTable,
      fetchAllFolders,
      folderData,
      openModalEdit,
      tableColumns,
      totalFolders,
      loading,
      dateTime,
      canModify,
      confirmDelete,
      refetchFolderData,
      folderNameFilter,
    }
  },
}
</script>
